import { defineComponent } from 'vue';
export default defineComponent({
  name: 'FileUpload',
  props: {
    modelValue: {
      type: Object,
      "default": null
    },
    accept: {
      type: String,
      "default": '*'
    },
    multiple: {
      type: Boolean,
      "default": false
    },
    disabled: {
      type: Boolean,
      "default": false
    }
  },
  emits: ['update:model-value'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var onFileInputChange = function onFileInputChange(event) {
      emit('update:model-value', event.target.files);
    };
    return {
      onFileInputChange: onFileInputChange
    };
  }
});