import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
_pushScopeId("data-v-f9d60024");
var _hoisted_1 = {
  "class": "hue-tabs"
};
var _hoisted_2 = ["onClick"];
var _hoisted_3 = {
  "class": "hue-tab-container"
};
_popScopeId();
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, function (tab) {
    return _openBlock(), _createElementBlock("li", {
      key: tab.title,
      "class": _normalizeClass({
        active: _ctx.selectedTabRef === tab
      }),
      onClick: function onClick($event) {
        return _ctx.selectedTabRef = tab;
      }
    }, _toDisplayString(tab.title), 11 /* TEXT, CLASS, PROPS */, _hoisted_2);
  }), 128 /* KEYED_FRAGMENT */))]), _createElementVNode("div", _hoisted_3, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)])]);
}