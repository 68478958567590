import { defineComponent } from 'vue';
import BaseNavigationItem from "./BaseNavigationItem.vue";
export default defineComponent({
  name: 'UserDrawerContent',
  components: {
    BaseNavigationItem: BaseNavigationItem
  },
  inject: ['hideDrawer'],
  props: {
    item: {
      type: Object,
      required: true
    },
    children: {
      type: Array,
      required: false,
      "default": function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {};
  },
  computed: {
    logoutItem: function logoutItem() {
      return {
        handler: this.item.logoutHandler,
        name: 'logout'
      };
    }
  }
});