import { render } from "./FileUpload.vue?vue&type=template&id=7c52058d"
import script from "./FileUpload.vue?vue&type=script&lang=ts"
export * from "./FileUpload.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "7c52058d"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('7c52058d', script)) {
    api.reload('7c52058d', script)
  }
  
  module.hot.accept("./FileUpload.vue?vue&type=template&id=7c52058d", () => {
    api.rerender('7c52058d', render)
  })

}

script.__file = "desktop/core/src/desktop/js/components/FileUpload.vue"

export default script