import { defineComponent } from 'vue';
import { Duration as LuxonDuration } from 'luxon';
import I18n from "../utils/i18n";
var SHORT_LIMITS = [{
  unit: 'years',
  postfix: 'year',
  appendS: true
}, {
  unit: 'days',
  postfix: 'day',
  appendS: true
}, {
  unit: 'hours',
  postfix: 'h',
  appendS: false
}, {
  unit: 'minutes',
  postfix: 'm',
  appendS: false
}, {
  unit: 'seconds',
  postfix: 's',
  appendS: false
}, {
  unit: 'milliseconds',
  postfix: 'ms',
  appendS: false
}];
var _duration = function duration(value, _short) {
  var luxonDuration = LuxonDuration.fromMillis(value);
  if (_short) {
    for (var _i = 0, _SHORT_LIMITS = SHORT_LIMITS; _i < _SHORT_LIMITS.length; _i++) {
      var limit = _SHORT_LIMITS[_i];
      var val = Math.floor(luxonDuration.as(limit.unit));
      if (val >= 1) {
        return val + I18n(limit.postfix + (limit.appendS && val > 1 ? 's' : ''));
      }
    }
    return "0".concat(I18n('ms'));
  }
  return luxonDuration.toFormat('hh:mm:ss');
};
export { _duration as duration };
export default defineComponent({
  name: 'Duration',
  props: {
    value: {
      type: Number,
      required: true
    },
    "short": Boolean
  },
  computed: {
    duration: function duration() {
      return _duration(this.value, this["short"]);
    }
  }
});