import { defineComponent, ref, watch } from 'vue';
import { DateTime } from 'luxon';
import Datepicker from 'vue3-datepicker';
import HueLink from "./HueLink.vue";
import HueButton from "./HueButton.vue";
import I18n from "../utils/i18n";
import DropdownPanel from "./dropdown/DropdownPanel.vue";
var SECOND = 1000;
var MINUTE = 60 * SECOND;
var HOUR = 60 * MINUTE;
var DAY = 24 * HOUR;
var WEEK = 7 * DAY;
var MONTH = 365 / 12 * DAY;
var YEAR = 365 * DAY;
var RANGE_NOW = DateTime.utc();
var TODAY_START = RANGE_NOW.valueOf() - RANGE_NOW.startOf('day').valueOf();
var WEEK_START = RANGE_NOW.valueOf() - RANGE_NOW.startOf('week').valueOf();
var MONTH_START = RANGE_NOW.valueOf() - RANGE_NOW.startOf('month').valueOf();
var YEAR_START = RANGE_NOW.valueOf() - RANGE_NOW.startOf('year').valueOf();

// Non-custom ranges have relative from/to, custom are absolute
// due to third-party datepicker component.
var RANGE_SETS = [[{
  title: I18n('Last 7 days'),
  from: 7 * DAY,
  to: 0
}, {
  title: I18n('Last 30 days'),
  from: 30 * DAY,
  to: 0
}, {
  title: I18n('Last 60 days'),
  from: 60 * DAY,
  to: 0
}, {
  title: I18n('Last 90 days'),
  from: 90 * DAY,
  to: 0
}, {
  title: I18n('Last 6 months'),
  from: 6 * MONTH,
  to: 0
}, {
  title: I18n('Last 1 year'),
  from: YEAR,
  to: 0
}, {
  title: I18n('Last 2 years'),
  from: 2 * YEAR,
  to: 0
}, {
  title: I18n('Last 5 years'),
  from: 5 * YEAR,
  to: 0
}], [{
  title: I18n('Last 5 minutes'),
  from: 5 * MINUTE,
  to: 0
}, {
  title: I18n('Last 15 minutes'),
  from: 15 * MINUTE,
  to: 0
}, {
  title: I18n('Last 30 minutes'),
  from: 30 * MINUTE,
  to: 0
}, {
  title: I18n('Last 1 hour'),
  from: HOUR,
  to: 0
}, {
  title: I18n('Last 3 hours'),
  from: 3 * HOUR,
  to: 0
}, {
  title: I18n('Last 6 hours'),
  from: 6 * HOUR,
  to: 0
}, {
  title: I18n('Last 12 hours'),
  from: 12 * HOUR,
  to: 0
}, {
  title: I18n('Last 24 hours'),
  from: DAY,
  to: 0
}], [{
  title: I18n('Today'),
  from: TODAY_START,
  to: 0
}, {
  title: I18n('Yesterday'),
  from: TODAY_START + DAY,
  to: TODAY_START - 1
}, {
  title: I18n('This week'),
  from: WEEK_START,
  to: 0
}, {
  title: I18n('Last week'),
  from: WEEK_START + WEEK,
  to: WEEK_START - 1
}, {
  title: I18n('This month'),
  from: MONTH_START,
  to: 0
}, {
  title: I18n('Last month'),
  from: MONTH_START + MONTH,
  to: MONTH_START - 1
}, {
  title: I18n('This year'),
  from: YEAR_START,
  to: 0
}, {
  title: I18n('Last year'),
  from: YEAR_START + YEAR,
  to: YEAR_START - 1
}]];
var DEFAULT_RANGE = RANGE_SETS[0][0];
var CUSTOM_TITLE = I18n('Custom Range');
export default defineComponent({
  name: 'DateRangePicker',
  components: {
    Datepicker: Datepicker,
    DropdownPanel: DropdownPanel,
    HueButton: HueButton,
    HueLink: HueLink
  },
  props: {
    inline: {
      type: Boolean,
      required: false,
      "default": false
    }
  },
  emits: ['date-range-changed'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var selectedRange = ref(DEFAULT_RANGE);
    var isCustom = ref(false);
    var customFrom = ref(new Date(RANGE_NOW.toMillis() - DEFAULT_RANGE.from));
    var customTo = ref(new Date(RANGE_NOW.toMillis()));
    watch(customFrom, function () {
      if (customFrom.value.getTime() > customTo.value.getTime()) {
        customTo.value = customFrom.value;
      }
    });
    watch(customTo, function () {
      if (customTo.value.getTime() < customFrom.value.getTime()) {
        customFrom.value = customTo.value;
      }
    });
    var notify = function notify() {
      var range;
      if (isCustom.value) {
        range = {
          title: CUSTOM_TITLE,
          from: DateTime.fromJSDate(customFrom.value).startOf('day').valueOf(),
          to: DateTime.fromJSDate(customTo.value).endOf('day').valueOf(),
          custom: true
        };
      } else {
        var _selectedRange$value = selectedRange.value,
          title = _selectedRange$value.title,
          from = _selectedRange$value.from,
          to = _selectedRange$value.to;
        var now = DateTime.utc().valueOf();
        range = {
          title: title,
          from: now - from,
          to: now - to
        };
      }
      emit('date-range-changed', range);
    };
    var clear = function clear() {
      if (isCustom.value || selectedRange.value !== DEFAULT_RANGE) {
        customFrom.value = new Date(RANGE_NOW.toMillis() - DEFAULT_RANGE.from);
        customTo.value = new Date(RANGE_NOW.toMillis());
        selectedRange.value = DEFAULT_RANGE;
        isCustom.value = false;
        notify();
      }
    };
    var apply = function apply(closePanel) {
      isCustom.value = true;
      notify();
      closePanel();
    };
    var quickSelect = function quickSelect(range, closePanel) {
      isCustom.value = false;
      selectedRange.value = range;
      notify();
      closePanel();
    };
    return {
      apply: apply,
      clear: clear,
      CUSTOM_TITLE: CUSTOM_TITLE,
      customFrom: customFrom,
      customTo: customTo,
      isCustom: isCustom,
      quickSelect: quickSelect,
      RANGE_SETS: RANGE_SETS,
      selectedRange: selectedRange
    };
  }
});