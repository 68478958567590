import { render } from "./BaseNavigationItem.vue?vue&type=template&id=52f31dfb"
import script from "./BaseNavigationItem.vue?vue&type=script&lang=ts"
export * from "./BaseNavigationItem.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "52f31dfb"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('52f31dfb', script)) {
    api.reload('52f31dfb', script)
  }
  
  module.hot.accept("./BaseNavigationItem.vue?vue&type=template&id=52f31dfb", () => {
    api.rerender('52f31dfb', render)
  })

}

script.__file = "desktop/core/src/desktop/js/components/sidebar/BaseNavigationItem.vue"

export default script