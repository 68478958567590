import { render } from "./TimeAgo.vue?vue&type=template&id=4ec09bbc"
import script from "./TimeAgo.vue?vue&type=script&lang=ts"
export * from "./TimeAgo.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "4ec09bbc"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('4ec09bbc', script)) {
    api.reload('4ec09bbc', script)
  }
  
  module.hot.accept("./TimeAgo.vue?vue&type=template&id=4ec09bbc", () => {
    api.rerender('4ec09bbc', render)
  })

}

script.__file = "desktop/core/src/desktop/js/components/TimeAgo.vue"

export default script