import { render } from "./DropdownPanel.vue?vue&type=template&id=7aeb34b6"
import script from "./DropdownPanel.vue?vue&type=script&lang=ts"
export * from "./DropdownPanel.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "7aeb34b6"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('7aeb34b6', script)) {
    api.reload('7aeb34b6', script)
  }
  
  module.hot.accept("./DropdownPanel.vue?vue&type=template&id=7aeb34b6", () => {
    api.rerender('7aeb34b6', render)
  })

}

script.__file = "desktop/core/src/desktop/js/components/dropdown/DropdownPanel.vue"

export default script