import { render } from "./AccordionSubItem.vue?vue&type=template&id=17dad9ce"
import script from "./AccordionSubItem.vue?vue&type=script&lang=ts"
export * from "./AccordionSubItem.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "17dad9ce"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('17dad9ce', script)) {
    api.reload('17dad9ce', script)
  }
  
  module.hot.accept("./AccordionSubItem.vue?vue&type=template&id=17dad9ce", () => {
    api.rerender('17dad9ce', render)
  })

}

script.__file = "desktop/core/src/desktop/js/components/sidebar/AccordionSubItem.vue"

export default script