import { defineComponent, inject } from 'vue';
export default defineComponent({
  name: 'BaseNavigationItem',
  props: {
    item: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      "default": false
    },
    cssClasses: {
      type: String,
      "default": ''
    }
  },
  emits: ['click'],
  setup: function setup() {
    return {
      selectedItemChanged: inject('selectedItemChanged')
    };
  },
  methods: {
    onClick: function onClick(event) {
      if (this.selectedItemChanged) {
        this.selectedItemChanged(this.item.name);
      }
      if (this.item.handler) {
        this.item.handler(event);
      }
      this.$emit('click');
    }
  }
});