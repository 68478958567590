import { render } from "./BaseNavigationItemTooltip.vue?vue&type=template&id=466fff90"
import script from "./BaseNavigationItemTooltip.vue?vue&type=script&lang=ts"
export * from "./BaseNavigationItemTooltip.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "466fff90"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('466fff90', script)) {
    api.reload('466fff90', script)
  }
  
  module.hot.accept("./BaseNavigationItemTooltip.vue?vue&type=template&id=466fff90", () => {
    api.rerender('466fff90', render)
  })

}

script.__file = "desktop/core/src/desktop/js/components/sidebar/BaseNavigationItemTooltip.vue"

export default script