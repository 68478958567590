import { defineComponent } from 'vue';
import "./HueTable.scss";
export default defineComponent({
  name: 'HueTable',
  props: {
    rows: {
      type: Array,
      required: false,
      "default": function _default() {
        return [];
      }
    },
    columns: {
      type: Array,
      required: false,
      "default": function _default() {
        return [];
      }
    },
    caption: {
      type: String,
      "default": undefined
    },
    showHeader: {
      type: Boolean,
      required: false,
      "default": true
    },
    stickyHeader: {
      type: Boolean,
      required: false,
      "default": false
    },
    stickyFirstColumn: {
      type: Boolean,
      required: false,
      "default": false
    },
    clickableRows: {
      type: Boolean,
      "default": false
    }
  },
  emits: ['scroll-to-end', 'row-clicked'],
  methods: {
    hasCellSlot: function hasCellSlot(column) {
      return !!this.$slots[this.cellSlotName(column)];
    },
    cellSlotName: function cellSlotName(column) {
      return 'cell-' + column.key;
    },
    onContainerScroll: function onContainerScroll() {
      var containerEl = this.$refs.tableContainer;
      if (containerEl.scrollHeight === containerEl.scrollTop + containerEl.clientHeight) {
        this.$emit('scroll-to-end');
      }
    },
    cellClass: function cellClass(_cellClass, index) {
      // This prevents rendering of empty class="" for :class="[x,y]" when x and y are undefined
      // Possibly fixed in Vue 3
      if (_cellClass && this.stickyFirstColumn && index === 0) {
        return "".concat(_cellClass, " sticky-first-col");
      } else if (this.stickyFirstColumn && index === 0) {
        return 'sticky-first-col';
      }
      return _cellClass || null;
    },
    onRowClick: function onRowClick(row) {
      if (this.clickableRows) {
        this.$emit('row-clicked', row);
      }
    }
  }
});