import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, mergeProps as _mergeProps, createElementBlock as _createElementBlock, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment } from "vue";
var _hoisted_1 = {
  key: 1
};
var _hoisted_2 = {
  key: 0
};
var _hoisted_3 = {
  "class": "spinner-container"
};
var _hoisted_4 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_DropRightIcon = _resolveComponent("DropRightIcon");
  var _component_DropDownIcon = _resolveComponent("DropDownIcon");
  var _component_DatabaseIcon = _resolveComponent("DatabaseIcon");
  var _component_ViewIcon = _resolveComponent("ViewIcon");
  var _component_TableIcon = _resolveComponent("TableIcon");
  var _component_ColumnIcon = _resolveComponent("ColumnIcon");
  var _component_Spinner = _resolveComponent("Spinner");
  var _component_SqlAssistEntry = _resolveComponent("SqlAssistEntry", true);
  return _openBlock(), _createElementBlock(_Fragment, null, [!_ctx.root ? (_openBlock(), _createElementBlock("div", _mergeProps({
    key: 0
  }, _ctx.hasPossibleChildren ? {
    role: 'button',
    tabIndex: 0
  } : {}, {
    "class": {
      leaf: !_ctx.hasPossibleChildren
    },
    onClick: _cache[0] || (_cache[0] = function () {
      return _ctx.onEntryClick && _ctx.onEntryClick.apply(_ctx, arguments);
    })
  }), [_createElementVNode("span", null, [_ctx.showFirstLevelArrows && !_ctx.open ? (_openBlock(), _createBlock(_component_DropRightIcon, {
    key: 0
  })) : _ctx.showFirstLevelArrows && _ctx.open ? (_openBlock(), _createBlock(_component_DropDownIcon, {
    key: 1
  })) : _createCommentVNode("v-if", true), _ctx.entry.isDatabase() ? (_openBlock(), _createBlock(_component_DatabaseIcon, {
    key: 2
  })) : _ctx.entry.isView() ? (_openBlock(), _createBlock(_component_ViewIcon, {
    key: 3
  })) : _ctx.entry.isTable() ? (_openBlock(), _createBlock(_component_TableIcon, {
    key: 4
  })) : _ctx.entry.isField() ? (_openBlock(), _createBlock(_component_ColumnIcon, {
    key: 5
  })) : _createCommentVNode("v-if", true)]), _createTextVNode(" " + _toDisplayString(_ctx.entry.getDisplayName()), 1 /* TEXT */)], 16 /* FULL_PROPS */)) : _createCommentVNode("v-if", true), _ctx.open ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.loadingChildren ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [_createElementVNode("li", _hoisted_3, [_createVNode(_component_Spinner, {
    spin: true,
    inline: true,
    size: 'large'
  })])])) : _createCommentVNode("v-if", true), !_ctx.loadingChildren && _ctx.children && _ctx.children.length ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.children, function (childEntry) {
    return _openBlock(), _createElementBlock("li", {
      key: childEntry.getQualifiedPath()
    }, [_createVNode(_component_SqlAssistEntry, {
      entry: childEntry,
      "show-first-level-arrows": _ctx.root && _ctx.showFirstLevelArrows
    }, null, 8 /* PROPS */, ["entry", "show-first-level-arrows"])]);
  }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true)], 64 /* STABLE_FRAGMENT */);
}