import { defineComponent } from 'vue';
import SpacerItem from "./SpacerItem.vue";
import BaseNavigationItem from "./BaseNavigationItem.vue";
export default defineComponent({
  name: 'AccordionSubItem',
  components: {
    SpacerItem: SpacerItem,
    BaseNavigationItem: BaseNavigationItem
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    active: Boolean,
    disabled: {
      type: Boolean,
      "default": false
    }
  }
});