import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  "class": "hue-assist-panel"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_HueIcons = _resolveComponent("HueIcons");
  var _component_SqlAssistPanel = _resolveComponent("SqlAssistPanel");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_HueIcons), _createVNode(_component_SqlAssistPanel, {
    "use-breadcrumbs": true,
    "show-first-level-arrows": false
  })]);
}