function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { defineComponent } from 'vue';
import I18n from "../utils/i18n";
import DropdownPanel from "./dropdown/DropdownPanel.vue";
import HueButton from "./HueButton.vue";
import HueLink from "./HueLink.vue";
export default defineComponent({
  name: 'FacetSelector',
  components: {
    DropdownPanel: DropdownPanel,
    HueButton: HueButton,
    HueLink: HueLink
  },
  props: {
    valueLabels: {
      type: Object,
      required: false,
      "default": function _default() {
        return {};
      }
    },
    fieldLabel: {
      type: String,
      required: false,
      "default": null
    },
    facet: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      "default": false
    },
    filterEnabled: {
      type: Boolean,
      required: false,
      "default": false
    }
  },
  emits: ['facet-removed', 'facet-changed'],
  data: function data() {
    var facetKeys = this.facet.values.map(function (val) {
      return val.key;
    });
    return {
      selectedValues: facetKeys,
      previousSelection: []
    };
  },
  computed: {
    allSelected: {
      get: function get() {
        return this.selectedValues.length === this.facet.values.length;
      },
      set: function set(val) {
        if (val) {
          this.selectedValues = this.facet.values.map(function (val) {
            return val.key;
          });
        } else {
          this.selectedValues = [];
        }
      }
    },
    applyDisabled: function applyDisabled() {
      return !this.selectedValues.length;
    },
    label: function label() {
      if (this.allSelected) {
        return "".concat(this.fieldLabel || this.facet.facetField, ": ").concat(I18n('All'));
      }
      if (this.selectedValues.length === 1) {
        return "".concat(this.fieldLabel || this.facet.facetField, ": ").concat(this.valueLabels[this.selectedValues[0]] || this.selectedValues[0]);
      }
      if (this.selectedValues.length === 0) {
        return "".concat(this.fieldLabel || this.facet.facetField, ": ").concat(I18n('None'));
      }
      return "".concat(this.fieldLabel || this.facet.facetField, ": ").concat(I18n('Multiple'));
    }
  },
  created: function created() {
    var _this = this;
    this.$watch('facet.values.length', function () {
      var newValues = _this.facet.values.map(function (val) {
        return val.key;
      });
      if (!_this.lastKnownValues) {
        // Select all initially
        _this.selectedValues = newValues;
        _this.previousSelection = newValues;
      } else {
        // Keep previous selection on change
        var selected = new Set(_this.selectedValues);

        // Select any new values that might have appeared
        var oldValues = new Set(_this.lastKnownValues);
        _this.selectedValues = newValues.filter(function (newValue) {
          return selected.has(newValue) || !oldValues.has(newValue);
        });
      }
      _this.lastKnownValues = newValues;
    });
  },
  methods: {
    I18n: I18n,
    clear: function clear() {
      this.selectedValues = this.facet.values.map(function (val) {
        return val.key;
      });
      this.$emit('facet-removed', this.facet.facetField);
    },
    cancel: function cancel(closePanel) {
      this.selectedValues = _toConsumableArray(this.previousSelection);
      closePanel();
    },
    apply: function apply(closePanel) {
      this.previousSelection = _toConsumableArray(this.selectedValues);
      if (this.allSelected) {
        this.$emit('facet-removed', this.facet.facetField);
      } else {
        this.$emit('facet-changed', {
          field: this.facet.facetField,
          values: this.selectedValues
        });
      }
      closePanel();
    }
  }
});