import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = ["aria-hidden"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    "class": _normalizeClass(["sidebar-drawer", {
      open: _ctx.show
    }]),
    role: "dialog",
    "aria-hidden": !_ctx.show
  }, [_ctx.show ? _renderSlot(_ctx.$slots, "default", {
    key: 0
  }) : _createCommentVNode("v-if", true)], 10 /* CLASS, PROPS */, _hoisted_1);
}