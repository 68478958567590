import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives } from "vue";
var _hoisted_1 = {
  key: 0,
  "class": "magnify-icon fa fa-fw fa-search"
};
var _hoisted_2 = {
  key: 1,
  "class": "magnify-icon fa fa-fw fa-spinner fa-spin"
};
var _hoisted_3 = {
  autocomplete: "off"
};
var _hoisted_4 = ["value", "placeholder"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    "class": _normalizeClass(["hue-search-input", {
      'search-input-small': _ctx.small
    }])
  }, [_createElementVNode("div", null, [_ctx.showMagnify && !_ctx.spin ? (_openBlock(), _createElementBlock("i", _hoisted_1)) : _ctx.showMagnify && _ctx.spin ? (_openBlock(), _createElementBlock("i", _hoisted_2)) : _createCommentVNode("v-if", true), _createElementVNode("form", _hoisted_3, [_createElementVNode("input", {
    value: _ctx.modelValue,
    "class": _normalizeClass(["hue-search-input-el", {
      'magnify-icon-input': _ctx.showMagnify
    }]),
    autocorrect: "off",
    autocomplete: "do-not-autocomplete",
    autocapitalize: "off",
    spellcheck: "false",
    type: "text",
    placeholder: !_ctx.hasFocus && _ctx.placeholder || '',
    onInput: _cache[0] || (_cache[0] = function (event) {
      _ctx.$emit('input', event.target.value);
      _ctx.$emit('update:model-value', event.target.value);
    }),
    onFocusin: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.hasFocus = true;
    }),
    onFocusout: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.hasFocus = false;
    }),
    onKeyup: _cache[3] || (_cache[3] = _withKeys(function ($event) {
      return _ctx.$emit('search', $event.target.value);
    }, ["enter"]))
  }, null, 42 /* CLASS, PROPS, HYDRATE_EVENTS */, _hoisted_4), _withDirectives(_createElementVNode("input", {
    "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) {
      return _ctx.autocomplete = $event;
    }),
    "class": _normalizeClass(["hue-search-input-overlay", {
      'magnify-icon-input': _ctx.showMagnify
    }]),
    disabled: "",
    type: "text",
    autocomplete: "do-not-autocomplete"
  }, null, 2 /* CLASS */), [[_vModelText, _ctx.autocomplete]])])])], 2 /* CLASS */);
}