import { render } from "./ComboBox.vue?vue&type=template&id=5f914b66"
import script from "./ComboBox.vue?vue&type=script&lang=ts"
export * from "./ComboBox.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "5f914b66"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('5f914b66', script)) {
    api.reload('5f914b66', script)
  }
  
  module.hot.accept("./ComboBox.vue?vue&type=template&id=5f914b66", () => {
    api.rerender('5f914b66', render)
  })

}

script.__file = "desktop/core/src/desktop/js/components/ComboBox.vue"

export default script