import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
_pushScopeId("data-v-ba75b056");
var _hoisted_1 = {
  key: 0
};
var _hoisted_2 = {
  key: 1
};
_popScopeId();
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    ref: "logsContainer",
    "class": "logs-panel",
    onScroll: _cache[0] || (_cache[0] = function () {
      return _ctx.onScroll && _ctx.onScroll.apply(_ctx, arguments);
    })
  }, [_ctx.logs ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.logs), 1 /* TEXT */)) : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.I18n('There are currently no logs available.')), 1 /* TEXT */))], 544 /* HYDRATE_EVENTS, NEED_PATCH */);
}