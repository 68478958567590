import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_NavigationItem = _resolveComponent("NavigationItem");
  var _component_AccordionItem = _resolveComponent("AccordionItem");
  var _component_SectionItem = _resolveComponent("SectionItem");
  var _component_SpacerItem = _resolveComponent("SpacerItem");
  var _directive_overflow_on_hover = _resolveDirective("overflow-on-hover");
  return _withDirectives((_openBlock(), _createElementBlock("nav", {
    ref: "sidebarNav",
    "class": _normalizeClass(["sidebar-body", {
      'sidebar-body-overflow-top': _ctx.showOverflowIndicatorTop,
      'sidebar-body-overflow-bottom': _ctx.showOverflowIndicatorBtm
    }]),
    onScroll: _cache[0] || (_cache[0] = function () {
      return _ctx.onScroll && _ctx.onScroll.apply(_ctx, arguments);
    })
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, function (item, index) {
    return _openBlock(), _createElementBlock(_Fragment, null, [item.type === 'navigation' ? (_openBlock(), _createBlock(_component_NavigationItem, {
      key: item.name,
      item: item,
      "active-item-name": _ctx.activeItemName,
      "is-collapsed": _ctx.isCollapsed
    }, null, 8 /* PROPS */, ["item", "active-item-name", "is-collapsed"])) : item.type === 'accordion' ? (_openBlock(), _createBlock(_component_AccordionItem, {
      key: item.name,
      item: item,
      "active-item-name": _ctx.activeItemName,
      "is-collapsed": _ctx.isCollapsed
    }, null, 8 /* PROPS */, ["item", "active-item-name", "is-collapsed"])) : item.type === 'section' ? (_openBlock(), _createBlock(_component_SectionItem, {
      key: item.name,
      item: item,
      "active-item-name": _ctx.activeItemName,
      "is-collapsed": _ctx.isCollapsed
    }, null, 8 /* PROPS */, ["item", "active-item-name", "is-collapsed"])) : (_openBlock(), _createBlock(_component_SpacerItem, {
      key: 'space-' + index
    }))], 64 /* STABLE_FRAGMENT */);
  }), 256 /* UNKEYED_FRAGMENT */))], 34 /* CLASS, HYDRATE_EVENTS */)), [[_directive_overflow_on_hover, {
    direction: 'y'
  }]]);
}