import { renderSlot as _renderSlot, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = ["disabled"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("button", _mergeProps(_ctx.$attrs, {
    "class": ["hue-btn", {
      'hue-btn-primary': _ctx.primary,
      'hue-btn-alert': _ctx.alert,
      'hue-btn-borderless': _ctx.borderless,
      'hue-btn-small': _ctx.small,
      'hue-btn-large': _ctx.large
    }],
    type: "button",
    disabled: _ctx.disabled,
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('click');
    })
  }), [_renderSlot(_ctx.$slots, "default")], 16 /* FULL_PROPS */, _hoisted_1);
}