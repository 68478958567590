import { defineComponent, inject } from 'vue';
import AccordionSubItem from "./AccordionSubItem.vue";
import BaseNavigationItemTooltip from "./BaseNavigationItemTooltip.vue";
import SubscriptionTracker from 'components/utils/SubscriptionTracker';
export default defineComponent({
  name: 'AccordionItem',
  components: {
    BaseNavigationItemTooltip: BaseNavigationItemTooltip,
    AccordionSubItem: AccordionSubItem
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    isCollapsed: Boolean,
    activeItemName: {
      type: String,
      "default": ''
    }
  },
  setup: function setup() {
    return {
      subTracker: new SubscriptionTracker(),
      selectedItemChanged: inject('selectedItemChanged')
    };
  },
  data: function data() {
    return {
      isOpen: false,
      isTooltipScrolled: false,
      tooltip: null
    };
  },
  computed: {
    isActive: function isActive() {
      var _this = this;
      return this.item.name === this.activeItemName || this.item.children.some(function (item) {
        return item.name === _this.activeItemName;
      });
    },
    isUserMenu: function isUserMenu() {
      return this.item.name === 'user';
    },
    accordionItemsHeight: function accordionItemsHeight() {
      var el = this.$refs.accordionItems;
      if (this.isOpen && el) {
        return "".concat(el.scrollHeight, "px");
      }
      return '0';
    },
    tooltipStyle: function tooltipStyle() {
      if (!this.tooltip) {
        return {};
      }
      if (this.isCollapsed) {
        // Prevent the menu from showing outside the window
        var height = this.item.children.length * 32 + (this.isUserMenu ? 50 : 40);
        var diff = this.tooltip.top + height - window.innerHeight;
        if (diff > 0) {
          return {
            top: this.tooltip.top - diff - 5 + 'px',
            left: this.tooltip.right + 'px'
          };
        }
      }
      return {
        top: this.tooltip.top + 'px',
        left: this.tooltip.right + 'px',
        maxHeight: this.tooltip.maxHeight + 'px'
      };
    }
  },
  mounted: function mounted() {
    var _this2 = this;
    var containerEl = this.$refs.containerRef;
    if (containerEl) {
      var parent = containerEl.parentElement;
      if (parent) {
        this.subTracker.addEventListener(parent, 'scroll', function () {
          _this2.tooltip = null;
        });
      }
    }
  },
  unmounted: function unmounted() {
    this.subTracker.dispose();
  },
  methods: {
    getItemName: function getItemName(item) {
      if (item.type === 'navigation') {
        return item.name;
      }
    },
    onFocusOut: function onFocusOut() {
      var _this3 = this;
      if (!this.tooltip) {
        return;
      }
      window.setTimeout(function () {
        var el = _this3.$refs.containerRef;
        if (el && _this3.tooltip && _this3.tooltip.fromKeyboard && !el.contains(document.activeElement)) {
          _this3.tooltip = null;
        }
      }, 10);
    },
    onKeyPress: function onKeyPress(event) {
      if ((event.key === 'Enter' || event.key === ' ') && this.isCollapsed) {
        if (!this.tooltip) {
          this.openTooltip(event.target, true);
        } else {
          this.tooltip = null;
        }
      }
    },
    onMouseEnter: function onMouseEnter(event) {
      if (this.isCollapsed) {
        this.openTooltip(event.target);
      }
    },
    onMouseLeave: function onMouseLeave() {
      this.tooltip = null;
    },
    onTooltipAccordionItemsScroll: function onTooltipAccordionItemsScroll(event) {
      this.isTooltipScrolled = event.target.scrollTop > 0;
    },
    onTooltipClick: function onTooltipClick() {
      this.tooltip = null;
    },
    openTooltip: function openTooltip(el, fromKeyboard) {
      var rect = el.getBoundingClientRect();
      // maxHeight is needed for the edge-cases where the accordion tooltip content would
      // otherwise render outside the viewport.
      var maxHeight = window.innerHeight - rect.top;
      this.isTooltipScrolled = false;
      this.tooltip = {
        top: rect.top,
        right: rect.right,
        height: rect.height,
        maxHeight: maxHeight,
        fromKeyboard: fromKeyboard
      };
    },
    toggleOpen: function toggleOpen(event) {
      if (!this.isCollapsed) {
        this.isOpen = !this.isOpen;
      } else if (this.item.handler) {
        this.item.handler(event);
        if (this.selectedItemChanged) {
          var firstNavChild = this.item.children.find(function (item) {
            return item.name;
          });
          this.selectedItemChanged(firstNavChild && firstNavChild.name || this.item.name);
        }
      }
    }
  }
});