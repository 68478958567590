import { defineComponent, ref, watch, computed, toRefs } from 'vue';
import TypeaheadInput from "./TypeaheadInput.vue";
import DropdownMenuOptions from "./dropdown/DropdownMenuOptions.vue";
var getLabel = function getLabel(option) {
  return option.label || option;
};
export default defineComponent({
  name: 'ComboBox',
  components: {
    DropdownMenuOptions: DropdownMenuOptions,
    TypeaheadInput: TypeaheadInput
  },
  props: {
    modelValue: {
      type: String,
      "default": null
    },
    options: {
      type: Array,
      "default": function _default() {
        return [];
      }
    }
  },
  emits: ['update:model-value'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var _toRefs = toRefs(props),
      modelValue = _toRefs.modelValue,
      options = _toRefs.options;
    var inputText = ref('');
    var activeOption = ref(null);
    var inputElement = ref(null);
    var dropdownVisible = ref(false);
    var filteredOptions = ref([]);
    var typeaheadOption = computed(function () {
      return activeOption.value && getLabel(activeOption.value) || inputText.value;
    });
    var updateFromInput = function updateFromInput(input) {
      if (!options.value || !options.value.length) {
        return;
      }
      filteredOptions.value = options.value.filter(function (option) {
        return getLabel(option).startsWith(input);
      });
      if (!activeOption.value || !getLabel(activeOption.value).startsWith(input)) {
        if (!input) {
          activeOption.value = options.value[0];
        } else {
          activeOption.value = options.value.find(function (option) {
            return getLabel(option).startsWith(input);
          }) || null;
        }
      }
      if (activeOption.value && input !== getLabel(activeOption.value) && !dropdownVisible.value) {
        dropdownVisible.value = true;
      }
      var foundOption = options.value.find(function (option) {
        return input === getLabel(option);
      });
      if (foundOption) {
        emit('update:model-value', typeof foundOption === 'string' ? foundOption : foundOption.value);
      } else {
        emit('update:model-value', input);
      }
    };
    watch(modelValue, function () {
      if (typeof modelValue.value !== 'undefined' && options.value) {
        var found = options.value.find(function (val) {
          return modelValue.value === getLabel(val);
        });
        if (found) {
          activeOption.value = found;
        }
      }
    }, {
      immediate: true
    });
    watch(inputText, function (newVal) {
      updateFromInput(newVal);
    });
    var onDropdownClose = function onDropdownClose() {
      dropdownVisible.value = false;
    };
    var onFocus = function onFocus() {
      updateFromInput(inputText.value);
    };
    var onSelectOption = function onSelectOption(option) {
      dropdownVisible.value = false;
      inputText.value = getLabel(option);
    };
    var onOptionActive = function onOptionActive(option) {
      activeOption.value = option;
    };
    return {
      dropdownVisible: dropdownVisible,
      inputText: inputText,
      activeOption: activeOption,
      getLabel: getLabel,
      inputElement: inputElement,
      typeaheadOption: typeaheadOption,
      filteredOptions: filteredOptions,
      onDropdownClose: onDropdownClose,
      onFocus: onFocus,
      onOptionActive: onOptionActive,
      onSelectOption: onSelectOption
    };
  }
});