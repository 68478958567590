import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  key: 0,
  "class": "fa fa-spinner fa-spin"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_hue_icon = _resolveComponent("hue-icon");
  return _openBlock(), _createElementBlock("span", null, [_ctx.spin ? (_openBlock(), _createElementBlock("i", _hoisted_1)) : _ctx.icon ? (_openBlock(), _createBlock(_component_hue_icon, {
    key: 1,
    type: _ctx.icon
  }, null, 8 /* PROPS */, ["type"])) : _createCommentVNode("v-if", true)]);
}