import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    typeahead: {
      type: String,
      "default": ''
    },
    modelValue: {
      type: String,
      "default": ''
    }
  },
  emits: ['update:model-value', 'input-element', 'focus'],
  mounted: function mounted() {
    this.$emit('input-element', this.$refs.mainInputElement);
  },
  methods: {
    onKeyDown: function onKeyDown(event) {
      if (event.key === 'Tab' && this.typeahead && this.typeahead.startsWith(this.modelValue)) {
        this.$emit('update:model-value', this.typeahead);
        event.preventDefault();
      }
    }
  }
});