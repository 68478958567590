import { render } from "./NavigationItem.vue?vue&type=template&id=6b998a6c"
import script from "./NavigationItem.vue?vue&type=script&lang=ts"
export * from "./NavigationItem.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "6b998a6c"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('6b998a6c', script)) {
    api.reload('6b998a6c', script)
  }
  
  module.hot.accept("./NavigationItem.vue?vue&type=template&id=6b998a6c", () => {
    api.rerender('6b998a6c', render)
  })

}

script.__file = "desktop/core/src/desktop/js/components/sidebar/NavigationItem.vue"

export default script