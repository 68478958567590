import { render } from "./SectionItem.vue?vue&type=template&id=274735a5"
import script from "./SectionItem.vue?vue&type=script&lang=ts"
export * from "./SectionItem.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "274735a5"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('274735a5', script)) {
    api.reload('274735a5', script)
  }
  
  module.hot.accept("./SectionItem.vue?vue&type=template&id=274735a5", () => {
    api.rerender('274735a5', render)
  })

}

script.__file = "desktop/core/src/desktop/js/components/sidebar/SectionItem.vue"

export default script