import { defineComponent, computed, ref } from 'vue';
import "./Paginator.scss";
import HueIcon from "./HueIcon.vue";
import DropdownMenu from "./dropdown/DropdownMenu.vue";
import DropdownMenuButton from "./dropdown/DropdownMenuButton.vue";
var DEFAULT_LIMIT = 25;
var PRESET_LIMITS = [DEFAULT_LIMIT, 50, 100];
export default defineComponent({
  name: 'Paginator',
  components: {
    DropdownMenu: DropdownMenu,
    DropdownMenuButton: DropdownMenuButton,
    HueIcon: HueIcon
  },
  props: {
    totalEntries: {
      type: Number,
      required: true
    }
  },
  emits: ['page-changed'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var currentPage = ref(1);
    var limit = ref(DEFAULT_LIMIT);
    var offset = computed(function () {
      return (currentPage.value - 1) * limit.value;
    });
    var lastNotifiedPage = undefined;
    var notifyPageChanged = function notifyPageChanged() {
      var _lastNotifiedPage;
      if (((_lastNotifiedPage = lastNotifiedPage) === null || _lastNotifiedPage === void 0 ? void 0 : _lastNotifiedPage.offset) !== offset.value || lastNotifiedPage.limit !== limit.value || lastNotifiedPage.pageNumber !== currentPage.value) {
        lastNotifiedPage = {
          pageNumber: currentPage.value,
          offset: offset.value,
          limit: limit.value
        };
        emit('page-changed', lastNotifiedPage);
      }
    };
    return {
      currentPage: currentPage,
      limit: limit,
      offset: offset,
      notifyPageChanged: notifyPageChanged,
      presetLimits: PRESET_LIMITS
    };
  },
  computed: {
    lastDisplayedIndex: function lastDisplayedIndex() {
      return Math.min(this.offset + this.limit, this.totalEntries - 1);
    },
    totalPages: function totalPages() {
      return Math.ceil(this.totalEntries / this.limit) || 1;
    }
  },
  watch: {
    currentPage: function currentPage(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.notifyPageChanged();
      }
    }
  },
  mounted: function mounted() {
    this.notifyPageChanged();
  },
  methods: {
    gotoFirstPage: function gotoFirstPage() {
      this.currentPage = 1;
    },
    gotoPreviousPage: function gotoPreviousPage() {
      this.currentPage = Math.max(this.currentPage - 1, 1);
    },
    gotoNextPage: function gotoNextPage() {
      this.currentPage = Math.min(this.currentPage + 1, this.totalPages);
    },
    gotoLastPage: function gotoLastPage() {
      this.currentPage = this.totalPages;
    },
    setLimit: function setLimit(limit) {
      if (limit === this.limit) {
        return;
      }
      var factor = limit / this.limit;
      this.limit = limit;
      var lastCurrentPage = this.currentPage;
      this.currentPage = Math.floor((this.currentPage - 1) / factor) + 1;
      if (lastCurrentPage === this.currentPage) {
        this.notifyPageChanged(); // this.limit isn't watched
      }
    }
  }
});