import { defineComponent } from 'vue';
import I18n from "../utils/i18n";
var SECOND = {
  val: 1000,
  text: 'second'
};
var MINUTE = {
  val: SECOND.val * 60,
  text: 'minute'
};
var HOUR = {
  val: MINUTE.val * 60,
  text: 'hour'
};
var DAY = {
  val: HOUR.val * 24,
  text: 'day'
};
var MONTH = {
  val: DAY.val * (365 / 12),
  text: 'month'
};
var YEAR = {
  val: DAY.val * 365,
  text: 'year'
};
var LIMITS = [YEAR, MONTH, DAY, HOUR, MINUTE, SECOND];
var _timeAgo = function timeAgo(value) {
  var diff = Date.now() - value;
  for (var _i = 0, _LIMITS = LIMITS; _i < _LIMITS.length; _i++) {
    var limit = _LIMITS[_i];
    if (diff >= limit.val) {
      var val = Math.round(diff / limit.val);
      var postfix = I18n("".concat(limit.text).concat(val > 1 ? 's' : '', " ago"));
      return "".concat(val, " ").concat(postfix);
    }
  }
  return I18n('now');
};
export { _timeAgo as timeAgo };
export default defineComponent({
  name: 'TimeAgo',
  props: {
    value: {
      type: Number,
      required: true
    }
  },
  computed: {
    // TODO: Add timezone support
    timeAgo: function timeAgo() {
      return _timeAgo(this.value);
    }
  }
});