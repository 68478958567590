import { render } from "./Tabs.vue?vue&type=template&id=f9d60024&scoped=true"
import script from "./Tabs.vue?vue&type=script&lang=ts"
export * from "./Tabs.vue?vue&type=script&lang=ts"

import "./Tabs.vue?vue&type=style&index=0&id=f9d60024&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-f9d60024"
/* hot reload */
if (module.hot) {
  script.__hmrId = "f9d60024"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('f9d60024', script)) {
    api.reload('f9d60024', script)
  }
  
  module.hot.accept("./Tabs.vue?vue&type=template&id=f9d60024&scoped=true", () => {
    api.rerender('f9d60024', render)
  })

}

script.__file = "desktop/core/src/desktop/js/components/Tabs.vue"

export default script