import { defineComponent } from 'vue';
import { overflowOnHover } from 'components/directives/overflowOnHoverDirective';
import AccordionItem from "./AccordionItem.vue";
import NavigationItem from "./NavigationItem.vue";
import SectionItem from "./SectionItem.vue";
import SpacerItem from "./SpacerItem.vue";
import defer from 'utils/timing/defer';
export default defineComponent({
  name: 'SidebarBody',
  components: {
    SpacerItem: SpacerItem,
    NavigationItem: NavigationItem,
    SectionItem: SectionItem,
    AccordionItem: AccordionItem
  },
  directives: {
    'overflow-on-hover': overflowOnHover
  },
  props: {
    items: {
      type: Object,
      required: true
    },
    isCollapsed: {
      type: Boolean,
      required: false
    },
    activeItemName: {
      type: String,
      "default": ''
    }
  },
  data: function data() {
    return {
      showOverflowIndicatorTop: false,
      showOverflowIndicatorBtm: false
    };
  },
  watch: {
    items: function items() {
      var _this = this;
      defer(function () {
        _this.detectOverflow(_this.$refs.sidebarNav);
      });
    }
  },
  methods: {
    onScroll: function onScroll(evt) {
      var el = evt.target;
      this.detectOverflow(el);
    },
    detectOverflow: function detectOverflow(el) {
      if (!el) {
        return;
      }
      var hasOverflowOnTop = el.scrollTop > 0;
      var hasOverflowOnBtm = el.scrollHeight - el.scrollTop > el.clientHeight;
      if (hasOverflowOnTop && hasOverflowOnBtm) {
        this.showOverflowIndicatorTop = true;
        this.showOverflowIndicatorBtm = true;
      } else if (hasOverflowOnTop) {
        this.showOverflowIndicatorTop = true;
        this.showOverflowIndicatorBtm = false;
      } else if (hasOverflowOnBtm) {
        this.showOverflowIndicatorTop = false;
        this.showOverflowIndicatorBtm = true;
      } else {
        this.showOverflowIndicatorTop = false;
        this.showOverflowIndicatorBtm = false;
      }
    }
  }
});