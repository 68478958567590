import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    "class": _normalizeClass(["sidebar-sidebar-item-tooltip", {
      'sidebar-sidebar-item-tooltip-visible': _ctx.visible
    }]),
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('click');
    })
  }, [_renderSlot(_ctx.$slots, "default")], 2 /* CLASS */);
}