import { defineComponent } from 'vue';
export default defineComponent({
  name: 'HueIcon',
  props: {
    type: {
      type: String,
      required: true
    }
  },
  computed: {
    xlinkAttr: function xlinkAttr() {
      return '#' + this.type;
    }
  }
});