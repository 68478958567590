import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue";
var _hoisted_1 = {
  key: 2
};
var _hoisted_2 = {
  key: 3
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_FileUpload = _resolveComponent("FileUpload");
  var _component_Spinner = _resolveComponent("Spinner");
  var _component_HueButton = _resolveComponent("HueButton");
  var _component_Modal = _resolveComponent("Modal");
  return _ctx.modelValue ? (_openBlock(), _createBlock(_component_Modal, {
    key: 0,
    header: _ctx.header || _ctx.I18n('Import Hue Documents'),
    onClose: _ctx.onModalClose
  }, {
    body: _withCtx(function () {
      return [!_ctx.uploadStats && !_ctx.importingDocuments ? (_openBlock(), _createBlock(_component_FileUpload, {
        key: 0,
        modelValue: _ctx.selectedFiles,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return _ctx.selectedFiles = $event;
        }),
        accept: '.json',
        disabled: _ctx.fileSelectionDisabled
      }, null, 8 /* PROPS */, ["modelValue", "accept", "disabled"])) : _ctx.importingDocuments ? (_openBlock(), _createBlock(_component_Spinner, {
        key: 1,
        inline: true,
        label: _ctx.I18n('Importing....')
      }, null, 8 /* PROPS */, ["label"])) : (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", null, [_createTextVNode(_toDisplayString(_ctx.I18n('Imported: ')) + " ", 1 /* TEXT */), _createElementVNode("span", null, _toDisplayString(_ctx.uploadStats.count), 1 /* TEXT */)]), _createElementVNode("div", null, [_createTextVNode(_toDisplayString(_ctx.I18n('Created: ')) + " ", 1 /* TEXT */), _createElementVNode("span", null, _toDisplayString(_ctx.uploadStats.created_count), 1 /* TEXT */)]), _createElementVNode("div", null, [_createTextVNode(_toDisplayString(_ctx.I18n('Updated: ')) + " ", 1 /* TEXT */), _createElementVNode("span", null, _toDisplayString(_ctx.uploadStats.updated_count), 1 /* TEXT */)])])), _ctx.failed ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.I18n('Import failed!')), 1 /* TEXT */)) : _createCommentVNode("v-if", true)];
    }),
    footer: _withCtx(function () {
      return [_createVNode(_component_HueButton, {
        disabled: _ctx.importingDocuments,
        onClick: _ctx.onModalClose
      }, {
        "default": _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_ctx.I18n('Close')), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["disabled", "onClick"]), !_ctx.uploadStats ? (_openBlock(), _createBlock(_component_HueButton, {
        key: 0,
        alert: true,
        disabled: _ctx.importDisabled,
        onClick: _ctx.onImport
      }, {
        "default": _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_ctx.I18n('Import')), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["disabled", "onClick"])) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "onClose"])) : _createCommentVNode("v-if", true);
}