import { render } from "./SearchInput.vue?vue&type=template&id=4a3380c2"
import script from "./SearchInput.vue?vue&type=script&lang=ts"
export * from "./SearchInput.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "4a3380c2"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('4a3380c2', script)) {
    api.reload('4a3380c2', script)
  }
  
  module.hot.accept("./SearchInput.vue?vue&type=template&id=4a3380c2", () => {
    api.rerender('4a3380c2', render)
  })

}

script.__file = "desktop/core/src/desktop/js/components/SearchInput.vue"

export default script