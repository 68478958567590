import { render } from "./SidebarBody.vue?vue&type=template&id=2bf23a2b"
import script from "./SidebarBody.vue?vue&type=script&lang=ts"
export * from "./SidebarBody.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "2bf23a2b"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('2bf23a2b', script)) {
    api.reload('2bf23a2b', script)
  }
  
  module.hot.accept("./SidebarBody.vue?vue&type=template&id=2bf23a2b", () => {
    api.rerender('2bf23a2b', render)
  })

}

script.__file = "desktop/core/src/desktop/js/components/sidebar/SidebarBody.vue"

export default script