import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue";
var _hoisted_1 = {
  "class": "sidebar-drawer-user-content"
};
var _hoisted_2 = {
  "class": "sidebar-drawer-user-content-header"
};
var _hoisted_3 = {
  "class": "sidebar-user-icon",
  role: "img"
};
var _hoisted_4 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_BaseNavigationItem = _resolveComponent("BaseNavigationItem");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.item.displayName[0].toUpperCase()), 1 /* TEXT */), _createElementVNode("div", null, [_createElementVNode("div", null, _toDisplayString(_ctx.item.displayName), 1 /* TEXT */), _ctx.item.userEmail ? (_openBlock(), _createElementBlock("small", _hoisted_4, _toDisplayString(_ctx.item.userEmail), 1 /* TEXT */)) : _createCommentVNode("v-if", true)])]), _createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.children, function (childItem, index) {
    return _openBlock(), _createElementBlock("li", {
      key: index
    }, [childItem.type === 'navigation' ? (_openBlock(), _createBlock(_component_BaseNavigationItem, {
      key: 0,
      item: childItem,
      onClick: _ctx.hideDrawer
    }, {
      "default": _withCtx(function () {
        return [_createTextVNode(_toDisplayString(childItem.displayName), 1 /* TEXT */)];
      }),
      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["item", "onClick"])) : _createCommentVNode("v-if", true)]);
  }), 128 /* KEYED_FRAGMENT */)), _createElementVNode("li", null, [_createVNode(_component_BaseNavigationItem, {
    item: _ctx.logoutItem,
    onClick: _ctx.hideDrawer
  }, {
    "default": _withCtx(function () {
      return [_createTextVNode(_toDisplayString(_ctx.item.logoutLabel), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["item", "onClick"])])])]);
}