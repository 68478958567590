import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
_pushScopeId("data-v-7ede5b83");
var _hoisted_1 = {
  "class": "inline-alert-title"
};
var _hoisted_2 = {
  "class": "inline-alert-message"
};
var _hoisted_3 = {
  key: 1
};
var _hoisted_4 = {
  "class": "inline-alert-details"
};
_popScopeId();
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    "class": _normalizeClass(['inline-alert', _ctx.definition.cssClass])
  }, [_ctx.showClose ? (_openBlock(), _createElementBlock("em", {
    key: 0,
    "class": "inline-alert-close fa fa-times alert-color",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('close');
    })
  })) : _createCommentVNode("v-if", true), _createElementVNode("em", {
    "class": _normalizeClass(['fa alert-icon alert-color', _ctx.definition.faClass])
  }, null, 2 /* CLASS */), _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.definition.title), 1 /* TEXT */), _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.message), 1 /* TEXT */), _ctx.showDetails ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_ctx.details ? (_openBlock(), _createElementBlock("a", {
    key: 0,
    "class": "more-less-button",
    onClick: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.showDetails = false;
    })
  }, _toDisplayString(_ctx.I18n('Less details')), 1 /* TEXT */)) : _createCommentVNode("v-if", true), _createElementVNode("pre", _hoisted_4, _toDisplayString(_ctx.details), 1 /* TEXT */)])) : _ctx.details ? (_openBlock(), _createElementBlock("a", {
    key: 2,
    "class": "more-less-button",
    onClick: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.showDetails = true;
    })
  }, _toDisplayString(_ctx.I18n('More details')), 1 /* TEXT */)) : _createCommentVNode("v-if", true)], 2 /* CLASS */);
}