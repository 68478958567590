import { defineComponent } from 'vue';
import DropdownDivider from "./DropdownDivider.vue";
import DropdownDrawer from "./DropdownDrawer.vue";
import DropdownMenuButton from "./DropdownMenuButton.vue";
export var getLabel = function getLabel(option) {
  return option.label || option;
};
export default defineComponent({
  components: {
    DropdownDivider: DropdownDivider,
    DropdownMenuButton: DropdownMenuButton,
    DropdownDrawer: DropdownDrawer
  },
  props: {
    options: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    keydownElement: {
      type: Object,
      "default": null
    },
    open: {
      type: Boolean,
      "default": false
    },
    forceBottomPlacement: {
      type: Boolean,
      "default": false
    }
  },
  emits: ['close', 'option-active', 'option-selected'],
  data: function data() {
    return {
      selectedIndex: 0,
      keydownDispose: null
    };
  },
  watch: {
    keydownElement: function keydownElement(element) {
      var _this = this;
      if (this.keydownDispose) {
        this.keydownDispose();
      }
      if (element) {
        var handler = function handler(event) {
          switch (event.key) {
            case 'ArrowDown':
              _this.selectedIndex = (_this.selectedIndex + 1) % _this.options.length;
              break;
            case 'ArrowUp':
              _this.selectedIndex = _this.selectedIndex !== 0 ? _this.selectedIndex - 1 : _this.options.length - 1;
              break;
            case 'Enter':
              _this.$emit('option-selected', _this.options[_this.selectedIndex]);
          }
        };
        element.addEventListener('keydown', handler);
        this.keydownDispose = function () {
          element.removeEventListener('keydown', handler);
        };
      }
    },
    selectedIndex: function selectedIndex(index) {
      if (this.options.length) {
        this.$emit('option-active', this.options[index]);
      }
    },
    options: function options() {
      this.selectedIndex = 0;
      if (this.options.length) {
        this.$emit('option-active', this.options[this.selectedIndex]);
      }
    }
  },
  beforeUnmount: function beforeUnmount() {
    if (this.keydownDispose) {
      this.keydownDispose();
    }
  },
  methods: {
    getLabel: getLabel,
    onOptionClick: function onOptionClick(option, index) {
      this.selectedIndex = index;
      this.$emit('option-selected', option);
      this.$emit('close');
    }
  }
});