import { defineComponent, onMounted, ref } from 'vue';
import "./DropdownPanel.scss";
import DropdownDrawer from "./DropdownDrawer.vue";
import HueButton from "../HueButton.vue";
import HueLink from "../HueLink.vue";
export default defineComponent({
  name: 'DropdownPanel',
  components: {
    DropdownDrawer: DropdownDrawer,
    HueButton: HueButton,
    HueLink: HueLink
  },
  props: {
    closeOnClick: {
      type: Boolean,
      "default": true
    },
    text: {
      type: String,
      "default": ''
    },
    link: {
      type: Boolean,
      "default": false
    },
    disabled: {
      type: Boolean,
      "default": false
    }
  },
  setup: function setup() {
    var panelElement = ref(undefined);
    var triggerElement = ref(undefined);
    var open = ref(false);
    onMounted(function () {
      var _panelElement$value;
      if ((_panelElement$value = panelElement.value) !== null && _panelElement$value !== void 0 && _panelElement$value.firstChild) {
        triggerElement.value = panelElement.value.firstChild;
      }
    });
    var toggleDrawer = function toggleDrawer() {
      open.value = !open.value;
    };
    var closeDrawer = function closeDrawer() {
      open.value = false;
    };
    return {
      closeDrawer: closeDrawer,
      open: open,
      panelElement: panelElement,
      toggleDrawer: toggleDrawer,
      triggerElement: triggerElement
    };
  }
});