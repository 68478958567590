import { defineComponent } from 'vue';
import "./AssistPanel.scss";
import SqlAssistPanel from "./SqlAssistPanel.vue";
import HueIcons from "../icons/vue/HueIcons.vue";
export default defineComponent({
  name: 'AssistPanel',
  components: {
    HueIcons: HueIcons,
    SqlAssistPanel: SqlAssistPanel
  }
});