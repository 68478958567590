import { render } from "./DropdownMenuButton.vue?vue&type=template&id=36f52a20"
import script from "./DropdownMenuButton.vue?vue&type=script&lang=ts"
export * from "./DropdownMenuButton.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "36f52a20"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('36f52a20', script)) {
    api.reload('36f52a20', script)
  }
  
  module.hot.accept("./DropdownMenuButton.vue?vue&type=template&id=36f52a20", () => {
    api.rerender('36f52a20', render)
  })

}

script.__file = "desktop/core/src/desktop/js/components/dropdown/DropdownMenuButton.vue"

export default script