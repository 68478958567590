import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
_pushScopeId("data-v-7f407876");
var _hoisted_1 = {
  "class": "facet-selector"
};
var _hoisted_2 = {
  key: 0,
  "class": "facet-select-all"
};
var _hoisted_3 = {
  "class": "facet-list-entry"
};
var _hoisted_4 = {
  key: 0
};
var _hoisted_5 = {
  key: 1
};
var _hoisted_6 = {
  "class": "facet-selection"
};
var _hoisted_7 = ["value"];
var _hoisted_8 = {
  key: 0
};
var _hoisted_9 = {
  "class": "facet-selector-actions"
};
_popScopeId();
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_hue_link = _resolveComponent("hue-link");
  var _component_hue_button = _resolveComponent("hue-button");
  var _component_dropdown_panel = _resolveComponent("dropdown-panel");
  return _openBlock(), _createBlock(_component_dropdown_panel, {
    text: _ctx.label,
    disabled: _ctx.disabled,
    "close-on-click": false
  }, {
    "default": _withCtx(function (slotProps) {
      return [_createElementVNode("div", _hoisted_1, [_ctx.facet.values.length > 1 ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("label", null, [_withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return _ctx.allSelected = $event;
        }),
        type: "checkbox"
      }, null, 512 /* NEED_PATCH */), [[_vModelCheckbox, _ctx.allSelected]]), _ctx.allSelected ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.I18n('Select None')), 1 /* TEXT */)) : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.I18n('Select All')), 1 /* TEXT */))])])])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_6, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.facet.values, function (facetValue) {
        return _openBlock(), _createElementBlock("div", {
          key: facetValue.key,
          "class": "facet-list-entry"
        }, [_createElementVNode("label", null, [_withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
            return _ctx.selectedValues = $event;
          }),
          type: "checkbox",
          value: facetValue.key
        }, null, 8 /* PROPS */, _hoisted_7), [[_vModelCheckbox, _ctx.selectedValues]]), _createTextVNode(" " + _toDisplayString(_ctx.valueLabels[facetValue.key] || facetValue.key) + " ", 1 /* TEXT */), facetValue.value ? (_openBlock(), _createElementBlock("span", _hoisted_8, "(" + _toDisplayString(facetValue.value) + ")", 1 /* TEXT */)) : _createCommentVNode("v-if", true)])]);
      }), 128 /* KEYED_FRAGMENT */))]), _createElementVNode("div", _hoisted_9, [_createVNode(_component_hue_link, {
        onClick: function onClick($event) {
          return _ctx.cancel(slotProps.closePanel);
        }
      }, {
        "default": _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_ctx.I18n('Cancel')), 1 /* TEXT */)];
        }),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]), _createVNode(_component_hue_button, {
        small: true,
        primary: true,
        disabled: _ctx.applyDisabled,
        onClick: function onClick($event) {
          return _ctx.apply(slotProps.closePanel);
        }
      }, {
        "default": _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_ctx.I18n('Apply')), 1 /* TEXT */)];
        }),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["disabled", "onClick"])])])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["text", "disabled"]);
}