import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue";
var _hoisted_1 = {
  key: 2
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_SpinnerLargeIcon = _resolveComponent("SpinnerLargeIcon");
  var _component_SpinnerSmallIcon = _resolveComponent("SpinnerSmallIcon");
  return _ctx.spin ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    "class": _normalizeClass(["spinner-container", {
      'spinner-overlay': _ctx.overlay,
      'spinner-inline': _ctx.inline,
      spinner: !_ctx.overlay && !_ctx.inline,
      'spinner-blackout': _ctx.blackout
    }])
  }, [_createElementVNode("div", {
    "class": _normalizeClass(["spinner-inner", {
      'spinner-center': _ctx.center,
      'spinner-large': _ctx.size === 'large',
      'spinner-xlarge': _ctx.size === 'xlarge'
    }]),
    style: _normalizeStyle({
      width: _ctx.center && _ctx.inline ? '100%' : null
    })
  }, [_ctx.size === 'large' || _ctx.size === 'xlarge' ? (_openBlock(), _createBlock(_component_SpinnerLargeIcon, {
    key: 0,
    "class": "spinner-spin"
  })) : (_openBlock(), _createBlock(_component_SpinnerSmallIcon, {
    key: 1,
    "class": "spinner-spin"
  })), _ctx.label ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.label), 1 /* TEXT */)) : _createCommentVNode("v-if", true)], 6 /* CLASS, STYLE */)], 2 /* CLASS */)) : _createCommentVNode("v-if", true);
}