import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
_pushScopeId("data-v-5fcea533");
var _hoisted_1 = {
  "class": "typeahead-input"
};
var _hoisted_2 = ["value"];
var _hoisted_3 = ["value"];
_popScopeId();
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("input", {
    ref: "mainInputElement",
    "class": "main-input",
    type: "text",
    value: _ctx.modelValue,
    autocomplete: "no",
    onFocus: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('focus', $event);
    }),
    onInput: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('update:model-value', $event.target.value);
    }),
    onKeydown: _cache[2] || (_cache[2] = function () {
      return _ctx.onKeyDown && _ctx.onKeyDown.apply(_ctx, arguments);
    })
  }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_2), _createElementVNode("input", {
    "class": "typeahead-placeholder",
    type: "text",
    value: _ctx.typeahead,
    disabled: ""
  }, null, 8 /* PROPS */, _hoisted_3)]);
}