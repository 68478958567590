import { renderSlot as _renderSlot, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue";
var _hoisted_1 = ["href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock(_Fragment, null, [_ctx.item.handler ? (_openBlock(), _createElementBlock("button", _mergeProps({
    key: 0
  }, _ctx.$attrs, {
    "class": [_ctx.cssClasses, "sidebar-base-btn"],
    onClick: _cache[0] || (_cache[0] = function () {
      return _ctx.onClick && _ctx.onClick.apply(_ctx, arguments);
    })
  }), [_renderSlot(_ctx.$slots, "default")], 16 /* FULL_PROPS */)) : _createCommentVNode("v-if", true), _ctx.item.url && !_ctx.item.handler ? (_openBlock(), _createElementBlock("a", _mergeProps({
    key: 1,
    "class": _ctx.cssClasses,
    href: _ctx.item.url
  }, _ctx.$attrs, {
    "class": "sidebar-base-link",
    onClick: _cache[1] || (_cache[1] = function () {
      return _ctx.onClick && _ctx.onClick.apply(_ctx, arguments);
    })
  }), [_renderSlot(_ctx.$slots, "default")], 16 /* FULL_PROPS */, _hoisted_1)) : _createCommentVNode("v-if", true)], 64 /* STABLE_FRAGMENT */);
}