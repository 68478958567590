import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_dropdown_panel = _resolveComponent("dropdown-panel");
  return _openBlock(), _createBlock(_component_dropdown_panel, {
    text: _ctx.text,
    link: _ctx.link
  }, {
    "default": _withCtx(function () {
      return [_createElementVNode("ul", null, [_renderSlot(_ctx.$slots, "default")])];
    }),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["text", "link"]);
}