import { render } from "./FacetSelector.vue?vue&type=template&id=7f407876&scoped=true"
import script from "./FacetSelector.vue?vue&type=script&lang=ts"
export * from "./FacetSelector.vue?vue&type=script&lang=ts"

import "./FacetSelector.vue?vue&type=style&index=0&id=7f407876&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-7f407876"
/* hot reload */
if (module.hot) {
  script.__hmrId = "7f407876"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('7f407876', script)) {
    api.reload('7f407876', script)
  }
  
  module.hot.accept("./FacetSelector.vue?vue&type=template&id=7f407876&scoped=true", () => {
    api.rerender('7f407876', render)
  })

}

script.__file = "desktop/core/src/desktop/js/components/FacetSelector.vue"

export default script