import { defineComponent } from 'vue';
export default defineComponent({
  name: 'BaseNavigationItemTooltip',
  props: {
    visible: {
      type: Boolean,
      "default": false
    }
  },
  emits: ['click']
});