import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_TypeaheadInput = _resolveComponent("TypeaheadInput");
  var _component_DropdownMenuOptions = _resolveComponent("DropdownMenuOptions");
  return _openBlock(), _createElementBlock("div", _mergeProps({
    "class": "combo-box"
  }, _ctx.$attrs), [_createVNode(_component_TypeaheadInput, {
    modelValue: _ctx.inputText,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return _ctx.inputText = $event;
    }),
    typeahead: _ctx.typeaheadOption,
    onFocusin: _ctx.onFocus,
    onInputElement: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.inputElement = $event;
    })
  }, null, 8 /* PROPS */, ["modelValue", "typeahead", "onFocusin"]), _createVNode(_component_DropdownMenuOptions, {
    options: _ctx.filteredOptions,
    open: _ctx.dropdownVisible,
    "keydown-element": _ctx.inputElement,
    onClose: _ctx.onDropdownClose,
    onOptionSelected: _ctx.onSelectOption,
    onOptionActive: _ctx.onOptionActive
  }, null, 8 /* PROPS */, ["options", "open", "keydown-element", "onClose", "onOptionSelected", "onOptionActive"])], 16 /* FULL_PROPS */);
}