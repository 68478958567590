import { defineComponent } from 'vue';
import "./HueIcons.scss";
export default defineComponent({
  name: 'HueIcons',
  setup: function setup() {
    var alreadyPresentInDom = !!document.getElementById('hueIconSprites');
    return {
      alreadyPresentInDom: alreadyPresentInDom
    };
  }
});