import { render } from "./HumanByteSize.vue?vue&type=template&id=3376ab36"
import script from "./HumanByteSize.vue?vue&type=script&lang=ts"
export * from "./HumanByteSize.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "3376ab36"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('3376ab36', script)) {
    api.reload('3376ab36', script)
  }
  
  module.hot.accept("./HumanByteSize.vue?vue&type=template&id=3376ab36", () => {
    api.rerender('3376ab36', render)
  })

}

script.__file = "desktop/core/src/desktop/js/components/HumanByteSize.vue"

export default script