import { render } from "./DropdownMenu.vue?vue&type=template&id=58afb04e"
import script from "./DropdownMenu.vue?vue&type=script&lang=ts"
export * from "./DropdownMenu.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "58afb04e"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('58afb04e', script)) {
    api.reload('58afb04e', script)
  }
  
  module.hot.accept("./DropdownMenu.vue?vue&type=template&id=58afb04e", () => {
    api.rerender('58afb04e', render)
  })

}

script.__file = "desktop/core/src/desktop/js/components/dropdown/DropdownMenu.vue"

export default script