import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
_pushScopeId("data-v-463c985e");
var _hoisted_1 = {
  "class": "hue-icon"
};
var _hoisted_2 = ["href"];
_popScopeId();
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("svg", _hoisted_1, [_createElementVNode("use", {
    href: _ctx.xlinkAttr
  }, null, 8 /* PROPS */, _hoisted_2)]);
}