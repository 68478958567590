import { defineComponent } from 'vue';
import onHueLinkClick from 'utils/html/onHueLinkClick';
export default defineComponent({
  name: 'HueLink',
  props: {
    url: {
      type: String,
      required: false,
      "default": ''
    }
  },
  emits: ['click'],
  methods: {
    clicked: function clicked(event) {
      if (this.url && this.$attrs.target) {
        onHueLinkClick(event, this.url, this.$attrs.target);
      } else {
        this.$emit('click');
      }
    }
  }
});