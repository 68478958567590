// Licensed to Cloudera, Inc. under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  Cloudera, Inc. licenses this file
// to you under the Apache License, Version 2.0 (the
// 'License'); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an 'AS IS' BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import defer from 'utils/timing/defer';
export var removeClickOutsideHandler = function removeClickOutsideHandler(element) {
  if (element.clickOutsideHandler) {
    document.removeEventListener('click', element.clickOutsideHandler);
    element.clickOutsideHandler = undefined;
  }
};
export var addClickOutsideHandler = function addClickOutsideHandler(element, callback) {
  removeClickOutsideHandler(element);
  element.clickOutsideHandler = function (event) {
    if (document.contains(event.target) && !element.contains(event.target)) {
      callback(event);
    }
  };
  document.addEventListener('click', element.clickOutsideHandler);
  return function () {
    removeClickOutsideHandler(element);
  };
};
export var clickOutsideDirective = {
  mounted: function mounted(element, binding) {
    defer(function () {
      if (binding.value && typeof binding.value === 'function') {
        addClickOutsideHandler(element, binding.value);
      }
    });
  },
  unmounted: removeClickOutsideHandler
};