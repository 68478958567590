import { defineComponent } from 'vue';
import SubscriptionTracker from 'components/utils/SubscriptionTracker';
import defer from 'utils/timing/defer';
export default defineComponent({
  name: 'SidebarDrawer',
  provide: function provide() {
    var _this = this;
    return {
      hideDrawer: function hideDrawer() {
        _this.$emit('close');
      }
    };
  },
  props: {
    show: Boolean
  },
  emits: ['close'],
  data: function data() {
    return {
      subTracker: new SubscriptionTracker(),
      deferredShow: false
    };
  },
  watch: {
    show: function show(newValue) {
      var _this2 = this;
      // deferredShow is used to prevent closing it immediately after the document click event triggered by opening
      defer(function () {
        _this2.deferredShow = newValue;
      });
    }
  },
  mounted: function mounted() {
    var _this3 = this;
    this.subTracker.addEventListener(document, 'click', function (event) {
      if (_this3.deferredShow && document.contains(event.target) && !_this3.$el.contains(event.target)) {
        _this3.$emit('close');
      }
    });
  }
});