import { defineComponent } from 'vue';
export default defineComponent({
  name: 'SectionItem',
  props: {
    activeItemName: {
      type: String,
      "default": ''
    },
    isCollapsed: {
      type: Boolean,
      required: false
    },
    item: {
      type: Object,
      required: true
    }
  }
});