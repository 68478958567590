import { render } from "./InlineAlert.vue?vue&type=template&id=7ede5b83&scoped=true"
import script from "./InlineAlert.vue?vue&type=script&lang=ts"
export * from "./InlineAlert.vue?vue&type=script&lang=ts"

import "./InlineAlert.vue?vue&type=style&index=0&id=7ede5b83&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-7ede5b83"
/* hot reload */
if (module.hot) {
  script.__hmrId = "7ede5b83"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('7ede5b83', script)) {
    api.reload('7ede5b83', script)
  }
  
  module.hot.accept("./InlineAlert.vue?vue&type=template&id=7ede5b83&scoped=true", () => {
    api.rerender('7ede5b83', render)
  })

}

script.__file = "desktop/core/src/desktop/js/components/InlineAlert.vue"

export default script