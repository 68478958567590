import { render } from "./ImportDocumentsModal.vue?vue&type=template&id=5c2ab44a"
import script from "./ImportDocumentsModal.vue?vue&type=script&lang=ts"
export * from "./ImportDocumentsModal.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "5c2ab44a"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('5c2ab44a', script)) {
    api.reload('5c2ab44a', script)
  }
  
  module.hot.accept("./ImportDocumentsModal.vue?vue&type=template&id=5c2ab44a", () => {
    api.rerender('5c2ab44a', render)
  })

}

script.__file = "desktop/core/src/desktop/js/components/ImportDocumentsModal.vue"

export default script