import { defineComponent, ref, provide } from 'vue';
export var ADD_TAB_KEY = 'addTab';
export var REMOVE_TAB_KEY = 'removeTab';
export var SELECTED_TAB_KEY = 'selectedTabRef';
export default defineComponent({
  name: 'Tabs',
  setup: function setup() {
    var tabs = ref([]);
    var selectedTabRef = ref(null);
    function addTab(tab) {
      if (!tabs.value.find(function (t) {
        return t.title === tab.title;
      })) {
        tabs.value.push(tab);
        if (tabs.value.length === 1) {
          selectedTabRef.value = tabs.value[0];
        }
      }
    }
    function removeTab(tab) {
      var index = tabs.value.indexOf(tab);
      if (index !== -1) {
        tabs.value.splice(index, 1);
        if (tab === selectedTabRef.value && tabs.value.length) {
          selectedTabRef.value = tabs.value[Math.max(0, index - 1)];
        }
      }
    }
    provide(ADD_TAB_KEY, function (tab) {
      return addTab(tab);
    });
    provide(REMOVE_TAB_KEY, function (tab) {
      return removeTab(tab);
    });
    provide(SELECTED_TAB_KEY, selectedTabRef);
    return {
      tabs: tabs,
      selectedTabRef: selectedTabRef
    };
  }
});