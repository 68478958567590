import { defineComponent } from 'vue';
import "./HueButton.scss";
export default defineComponent({
  name: 'HueButton',
  props: {
    primary: {
      type: Boolean,
      required: false
    },
    alert: {
      type: Boolean,
      required: false
    },
    borderless: {
      type: Boolean,
      required: false
    },
    small: {
      type: Boolean,
      required: false
    },
    large: {
      type: Boolean,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false
    }
  },
  emits: ['click']
});