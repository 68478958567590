import { defineComponent } from 'vue';
import "./SearchInput.scss";
import I18n from "../utils/i18n";
export default defineComponent({
  name: 'SearchInput',
  props: {
    showMagnify: {
      type: Boolean,
      required: false,
      "default": true
    },
    placeholder: {
      type: String,
      "default": I18n('Search...')
    },
    modelValue: {
      type: String,
      "default": ''
    },
    small: {
      type: Boolean,
      "default": false
    }
  },
  emits: ['input', 'search', 'update:model-value'],
  data: function data() {
    return {
      spin: false,
      autocomplete: '',
      throttle: -1,
      hasFocus: false
    };
  }
});