import { render } from "./HueTable.vue?vue&type=template&id=c36253b4"
import script from "./HueTable.vue?vue&type=script&lang=ts"
export * from "./HueTable.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "c36253b4"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('c36253b4', script)) {
    api.reload('c36253b4', script)
  }
  
  module.hot.accept("./HueTable.vue?vue&type=template&id=c36253b4", () => {
    api.rerender('c36253b4', render)
  })

}

script.__file = "desktop/core/src/desktop/js/components/HueTable.vue"

export default script