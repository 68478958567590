import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_DropdownMenuButton = _resolveComponent("DropdownMenuButton");
  var _component_DropdownDivider = _resolveComponent("DropdownDivider");
  var _component_DropdownDrawer = _resolveComponent("DropdownDrawer");
  return _openBlock(), _createBlock(_component_DropdownDrawer, {
    open: _ctx.open && _ctx.options.length > 0,
    "force-bottom-placement": _ctx.forceBottomPlacement,
    "trigger-element": _ctx.keydownElement,
    onClose: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('close');
    })
  }, {
    "default": _withCtx(function () {
      return [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, function (option, idx) {
        return _openBlock(), _createElementBlock(_Fragment, {
          key: option.value || option.label || option
        }, [!option.divider ? (_openBlock(), _createBlock(_component_DropdownMenuButton, {
          key: 0,
          "class": _normalizeClass({
            selected: idx === _ctx.selectedIndex
          }),
          onClick: function onClick($event) {
            return _ctx.onOptionClick(option, idx);
          }
        }, {
          "default": _withCtx(function () {
            return [_createTextVNode(_toDisplayString(_ctx.getLabel(option)), 1 /* TEXT */)];
          }),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["class", "onClick"])) : (_openBlock(), _createBlock(_component_DropdownDivider, {
          key: 1
        }))], 64 /* STABLE_FRAGMENT */);
      }), 128 /* KEYED_FRAGMENT */))])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["open", "force-bottom-placement", "trigger-element"]);
}