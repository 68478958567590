import { render } from "./LogsPanel.vue?vue&type=template&id=ba75b056&scoped=true"
import script from "./LogsPanel.vue?vue&type=script&lang=ts"
export * from "./LogsPanel.vue?vue&type=script&lang=ts"

import "./LogsPanel.vue?vue&type=style&index=0&id=ba75b056&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-ba75b056"
/* hot reload */
if (module.hot) {
  script.__hmrId = "ba75b056"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('ba75b056', script)) {
    api.reload('ba75b056', script)
  }
  
  module.hot.accept("./LogsPanel.vue?vue&type=template&id=ba75b056&scoped=true", () => {
    api.rerender('ba75b056', render)
  })

}

script.__file = "desktop/core/src/desktop/js/components/LogsPanel.vue"

export default script