import { defineComponent } from 'vue';
import "./Spinner.scss";
import SpinnerLargeIcon from "./icons/vue/SpinnerLargeIcon";
import SpinnerSmallIcon from "./icons/vue/SpinnerSmallIcon";
export default defineComponent({
  name: 'Spinner',
  components: {
    SpinnerSmallIcon: SpinnerSmallIcon,
    SpinnerLargeIcon: SpinnerLargeIcon
  },
  props: {
    spin: {
      type: Boolean,
      "default": true
    },
    size: {
      type: String,
      "default": 'default'
    },
    center: {
      type: Boolean,
      "default": false
    },
    overlay: {
      type: Boolean,
      "default": false
    },
    inline: {
      type: Boolean,
      "default": false
    },
    label: {
      type: String,
      "default": ''
    },
    blackout: {
      type: Boolean,
      "default": false
    }
  }
});