import { render } from "./SidebarDrawer.vue?vue&type=template&id=59bec1ba"
import script from "./SidebarDrawer.vue?vue&type=script&lang=ts"
export * from "./SidebarDrawer.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "59bec1ba"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('59bec1ba', script)) {
    api.reload('59bec1ba', script)
  }
  
  module.hot.accept("./SidebarDrawer.vue?vue&type=template&id=59bec1ba", () => {
    api.rerender('59bec1ba', render)
  })

}

script.__file = "desktop/core/src/desktop/js/components/sidebar/SidebarDrawer.vue"

export default script