import { defineComponent } from 'vue';
import I18n from "../utils/i18n";
export default defineComponent({
  name: 'LogsPanel',
  props: {
    logs: {
      type: String,
      "default": undefined
    },
    autoScroll: {
      type: Boolean,
      "default": true
    }
  },
  data: function data() {
    return {
      ignoreNextScrollEvent: false,
      userScrolled: false
    };
  },
  watch: {
    logs: function logs() {
      if (!this.autoScroll) {
        return;
      }
      var containerEl = this.$refs.logsContainer;
      if (!this.userScrolled && containerEl && containerEl.scrollHeight > containerEl.clientHeight) {
        this.ignoreNextScrollEvent = true;
        containerEl.scrollTop = containerEl.scrollHeight - containerEl.clientHeight;
      }
    }
  },
  methods: {
    I18n: I18n,
    onScroll: function onScroll() {
      if (!this.autoScroll) {
        return;
      }
      var containerEl = this.$refs.logsContainer;
      if (!this.ignoreNextScrollEvent && containerEl) {
        this.userScrolled = containerEl && containerEl.scrollHeight - containerEl.clientHeight - containerEl.scrollTop > 10;
      }
      this.ignoreNextScrollEvent = false;
    }
  }
});