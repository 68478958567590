import { defineComponent, inject } from 'vue';
import BaseNavigationItem from "./BaseNavigationItem.vue";
export default defineComponent({
  name: 'HelpDrawerContent',
  components: {
    BaseNavigationItem: BaseNavigationItem
  },
  props: {
    children: {
      type: Array,
      required: false,
      "default": function _default() {
        return [];
      }
    }
  },
  setup: function setup() {
    return {
      hideDrawer: inject('hideDrawer')
    };
  }
});