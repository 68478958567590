import { render } from "./SqlAssistEntry.vue?vue&type=template&id=ff20d47e"
import script from "./SqlAssistEntry.vue?vue&type=script&lang=ts"
export * from "./SqlAssistEntry.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "ff20d47e"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('ff20d47e', script)) {
    api.reload('ff20d47e', script)
  }
  
  module.hot.accept("./SqlAssistEntry.vue?vue&type=template&id=ff20d47e", () => {
    api.rerender('ff20d47e', render)
  })

}

script.__file = "desktop/core/src/desktop/js/components/assist/SqlAssistEntry.vue"

export default script