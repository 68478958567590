import { render } from "./Sidebar.vue?vue&type=template&id=d7d9642e"
import script from "./Sidebar.vue?vue&type=script&lang=ts"
export * from "./Sidebar.vue?vue&type=script&lang=ts"

import "./Sidebar.vue?vue&type=style&index=0&id=d7d9642e&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "d7d9642e"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('d7d9642e', script)) {
    api.reload('d7d9642e', script)
  }
  
  module.hot.accept("./Sidebar.vue?vue&type=template&id=d7d9642e", () => {
    api.rerender('d7d9642e', render)
  })

}

script.__file = "desktop/core/src/desktop/js/components/sidebar/Sidebar.vue"

export default script