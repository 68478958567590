import { render } from "./DropdownMenuOptions.vue?vue&type=template&id=0246a8c0"
import script from "./DropdownMenuOptions.vue?vue&type=script&lang=ts"
export * from "./DropdownMenuOptions.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "0246a8c0"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('0246a8c0', script)) {
    api.reload('0246a8c0', script)
  }
  
  module.hot.accept("./DropdownMenuOptions.vue?vue&type=template&id=0246a8c0", () => {
    api.rerender('0246a8c0', render)
  })

}

script.__file = "desktop/core/src/desktop/js/components/dropdown/DropdownMenuOptions.vue"

export default script