import { render } from "./HueIcons.vue?vue&type=template&id=490439b0"
import script from "./HueIcons.vue?vue&type=script&lang=ts"
export * from "./HueIcons.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "490439b0"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('490439b0', script)) {
    api.reload('490439b0', script)
  }
  
  module.hot.accept("./HueIcons.vue?vue&type=template&id=490439b0", () => {
    api.rerender('490439b0', render)
  })

}

script.__file = "desktop/core/src/desktop/js/components/icons/vue/HueIcons.vue"

export default script