import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  enctype: "multipart/form-data",
  novalidate: ""
};
var _hoisted_2 = ["accept", "disabled", "multiple"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("form", _hoisted_1, [_createElementVNode("input", {
    type: "file",
    accept: _ctx.accept,
    disabled: _ctx.disabled,
    multiple: _ctx.multiple,
    onChange: _cache[0] || (_cache[0] = function () {
      return _ctx.onFileInputChange && _ctx.onFileInputChange.apply(_ctx, arguments);
    })
  }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_2)]);
}