import { defineComponent } from 'vue';
import BaseNavigationItem from "./BaseNavigationItem.vue";
import BaseNavigationItemTooltip from "./BaseNavigationItemTooltip.vue";
import SubscriptionTracker from 'components/utils/SubscriptionTracker';
export default defineComponent({
  name: 'NavigationItem',
  components: {
    BaseNavigationItemTooltip: BaseNavigationItemTooltip,
    BaseNavigationItem: BaseNavigationItem
  },
  props: {
    isCollapsed: Boolean,
    item: {
      type: Object,
      required: true
    },
    activeItemName: {
      type: String,
      "default": ''
    }
  },
  setup: function setup() {
    return {
      subTracker: new SubscriptionTracker()
    };
  },
  data: function data() {
    return {
      tooltip: null
    };
  },
  computed: {
    isActive: function isActive() {
      return this.item.name === this.activeItemName;
    }
  },
  mounted: function mounted() {
    var _this = this;
    if (this.$parent) {
      this.subTracker.addEventListener(this.$parent.$el, 'scroll', function () {
        _this.tooltip = null;
      });
    }
  },
  unmounted: function unmounted() {
    this.subTracker.dispose();
  },
  methods: {
    showTooltip: function showTooltip(event) {
      if (!this.isCollapsed) {
        return;
      }
      this.tooltip = event.target.getBoundingClientRect();
    },
    hideTooltip: function hideTooltip() {
      this.tooltip = null;
    }
  }
});