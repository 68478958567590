// Licensed to Cloudera, Inc. under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  Cloudera, Inc. licenses this file
// to you under the Apache License, Version 2.0 (the
// 'License'); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an 'AS IS' BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

var getOverflowStyle = function getOverflowStyle(value) {
  if (value && value.direction === 'x') {
    return 'overflowX';
  }
  if (value && value.direction === 'y') {
    return 'overflowY';
  }
  return 'overflow';
};
export var overflowOnHover = {
  bind: function bind(el, binding) {
    var value = binding.value;
    var overflowStyle = getOverflowStyle(value);
    var initialOverflowValue = el.style[overflowStyle];
    el.style[overflowStyle] = 'hidden';
    var isTouch = false;
    var onTouchStart = function onTouchStart() {
      isTouch = true;
    };
    var showDelay = -1;
    var onMouseEnter = function onMouseEnter() {
      showDelay = window.setTimeout(function () {
        el.style[overflowStyle] = 'auto';
      }, 30);
    };
    var onMouseLeave = function onMouseLeave() {
      window.clearTimeout(showDelay);
      if (!isTouch) {
        el.style[overflowStyle] = 'hidden';
      }
    };
    el.addEventListener('touchstart', onTouchStart);
    el.addEventListener('mouseenter', onMouseEnter);
    el.addEventListener('mouseleave', onMouseLeave);
    el.disposeOverflowOnHover = function () {
      window.clearTimeout(showDelay);
      el.removeEventListener('touchstart', onTouchStart);
      el.removeEventListener('mouseenter', onMouseEnter);
      el.removeEventListener('mouseleave', onMouseLeave);
      el.style[overflowStyle] = initialOverflowValue;
    };
  },
  unbind: function unbind(el) {
    if (el.disposeOverflowOnHover) {
      el.disposeOverflowOnHover();
    }
  }
};