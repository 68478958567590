import { defineComponent } from 'vue';
var UNITS = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB'];
var _humanSize = function humanSize(value) {
  if (value === 0) {
    return '0 B';
  }
  if (!value) {
    return '';
  }
  if (value < 1024) {
    return "".concat(value, " B");
  }
  var unitIndex = Math.min(Math.floor(Math.log(value) / Math.log(1024)), UNITS.length - 1);
  var unitValue = Math.round(value / Math.pow(1024, unitIndex) * 10) / 10;
  return "".concat(unitValue, " ").concat(UNITS[unitIndex]);
};
export { _humanSize as humanSize };
export default defineComponent({
  name: 'HumanByteSize',
  props: {
    value: {
      type: Number,
      "default": 0
    }
  },
  computed: {
    humanSize: function humanSize() {
      return _humanSize(this.value);
    }
  }
});