import { defineComponent } from 'vue';
import "./Modal.scss";
import I18n from "../utils/i18n";
export default defineComponent({
  name: 'Modal',
  props: {
    header: {
      type: String,
      "default": ''
    }
  },
  emits: ['close'],
  methods: {
    I18n: I18n
  }
});