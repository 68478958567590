import { render } from "./Spinner.vue?vue&type=template&id=06efd859"
import script from "./Spinner.vue?vue&type=script&lang=ts"
export * from "./Spinner.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "06efd859"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('06efd859', script)) {
    api.reload('06efd859', script)
  }
  
  module.hot.accept("./Spinner.vue?vue&type=template&id=06efd859", () => {
    api.rerender('06efd859', render)
  })

}

script.__file = "desktop/core/src/desktop/js/components/Spinner.vue"

export default script