import { defineComponent, inject } from 'vue';
import { SELECTED_TAB_KEY, ADD_TAB_KEY, REMOVE_TAB_KEY } from "./Tabs.vue";
export default defineComponent({
  name: 'Tab',
  props: {
    title: {
      type: String,
      required: true
    },
    lazy: {
      type: Boolean,
      "default": false
    }
  },
  setup: function setup(props) {
    var addTab = inject(ADD_TAB_KEY);
    var removeTab = inject(REMOVE_TAB_KEY);
    var selectedTabRef = inject(SELECTED_TAB_KEY);
    return {
      addTab: addTab,
      removeTab: removeTab,
      selectedTabRef: selectedTabRef,
      ref: {
        title: props.title
      }
    };
  },
  data: function data() {
    return {
      rendered: false
    };
  },
  computed: {
    isActive: function isActive() {
      var _this$selectedTabRef;
      return this.ref.title === ((_this$selectedTabRef = this.selectedTabRef) === null || _this$selectedTabRef === void 0 ? void 0 : _this$selectedTabRef.title);
    }
  },
  watch: {
    selectedTabRef: function selectedTabRef() {
      if (this.isActive) {
        this.rendered = true;
      }
    }
  },
  mounted: function mounted() {
    if (this.addTab) {
      this.addTab(this.ref);
    }
  } // TODO
  // destroyed(): was deprecated, need to rearchitect the component.
  // Whenever parent is rendered mount, unmount is called causing an to prevent infinit loop.
  // unmounted(): void {
  //   if (this.removeTab) {
  //     this.removeTab(this.def);
  //   }
  // }
});