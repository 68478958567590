import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    ref: "outerPanelElement",
    "class": _normalizeClass(["hue-dropdown-drawer", {
      open: _ctx.open
    }]),
    style: _normalizeStyle(_ctx.parentPosition),
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.closeOnClick && _ctx.closeDrawer();
    })
  }, [_ctx.open ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    ref: "innerPanelElement",
    "class": "hue-dropdown-drawer-inner",
    style: _normalizeStyle(_ctx.position)
  }, [_renderSlot(_ctx.$slots, "default")], 4 /* STYLE */)) : _createCommentVNode("v-if", true)], 6 /* CLASS, STYLE */);
}