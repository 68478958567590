import HueIcon from "./HueIcon.vue";
import { defineComponent } from 'vue';

// TODO: Support additional statuses
// TODO: Support spinner
var ICON_MAPPING = {
  success: 'hi-status-success',
  finished: 'hi-status-success',
  error: 'hi-status-error',
  exception: 'hi-status-error',
  warning: 'hi-status-warning',
  stopped: 'hi-status-stopped'
};
export default defineComponent({
  name: 'StatusIndicator',
  components: {
    HueIcon: HueIcon
  },
  props: {
    value: {
      type: String,
      required: true,
      "default": 'unknown'
    }
  },
  computed: {
    icon: function icon() {
      return ICON_MAPPING[this.value.toLowerCase()];
    },
    spin: function spin() {
      var lowerValue = this.value.toLowerCase();
      return lowerValue === 'started' || lowerValue === 'running';
    }
  }
});