import { render } from "./SpacerItem.vue?vue&type=template&id=62efb662"
import script from "./SpacerItem.vue?vue&type=script&lang=ts"
export * from "./SpacerItem.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "62efb662"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('62efb662', script)) {
    api.reload('62efb662', script)
  }
  
  module.hot.accept("./SpacerItem.vue?vue&type=template&id=62efb662", () => {
    api.rerender('62efb662', render)
  })

}

script.__file = "desktop/core/src/desktop/js/components/sidebar/SpacerItem.vue"

export default script