import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  key: 0
};
var _hoisted_2 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _ctx$modelValue;
  var _component_ConnectorIcon = _resolveComponent("ConnectorIcon");
  var _component_DropdownMenuButton = _resolveComponent("DropdownMenuButton");
  var _component_DropdownMenu = _resolveComponent("DropdownMenu");
  return _openBlock(), _createElementBlock(_Fragment, null, [_ctx.listView && _ctx.connectors.length > 1 ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.connectors, function (connector) {
    return _openBlock(), _createElementBlock("li", {
      key: connector.id
    }, [_createElementVNode("div", {
      onClick: function onClick($event) {
        return _ctx.connectorSelected(connector);
      }
    }, [_createVNode(_component_ConnectorIcon), _createTextVNode(" " + _toDisplayString(connector.displayName), 1 /* TEXT */)], 8 /* PROPS */, _hoisted_2)]);
  }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true), !_ctx.listView && _ctx.connectors.length > 1 ? (_openBlock(), _createBlock(_component_DropdownMenu, {
    key: 1,
    link: true,
    text: ((_ctx$modelValue = _ctx.modelValue) === null || _ctx$modelValue === void 0 ? void 0 : _ctx$modelValue.connector.name) || _ctx.I18n('Source')
  }, {
    "default": _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.connectors, function (connector) {
        return _openBlock(), _createBlock(_component_DropdownMenuButton, {
          key: connector.id,
          onClick: function onClick($event) {
            return _ctx.connectorSelected(connector);
          }
        }, {
          "default": _withCtx(function () {
            return [_createTextVNode(_toDisplayString(connector.displayName), 1 /* TEXT */)];
          }),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]);
      }), 128 /* KEYED_FRAGMENT */))];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["text"])) : _createCommentVNode("v-if", true)], 64 /* STABLE_FRAGMENT */);
}