import { render } from "./SqlAssistPanel.vue?vue&type=template&id=44a57e53"
import script from "./SqlAssistPanel.vue?vue&type=script&lang=ts"
export * from "./SqlAssistPanel.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "44a57e53"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('44a57e53', script)) {
    api.reload('44a57e53', script)
  }
  
  module.hot.accept("./SqlAssistPanel.vue?vue&type=template&id=44a57e53", () => {
    api.rerender('44a57e53', render)
  })

}

script.__file = "desktop/core/src/desktop/js/components/assist/SqlAssistPanel.vue"

export default script