import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_Sidebar = _resolveComponent("Sidebar");
  return _openBlock(), _createBlock(_component_Sidebar, {
    "active-item-name": _ctx.activeItemName,
    "drawer-topic": _ctx.drawerTopic,
    "help-drawer-children": _ctx.helpDrawerChildren,
    "help-drawer-item": _ctx.helpDrawerItem,
    "hue-version": _ctx.hueVersion,
    "img-version": _ctx.imgVersion,
    "is-collapsed": _ctx.isCollapsed,
    "sidebar-items": _ctx.sidebarItems,
    "use-drawer-for-help": false,
    "use-drawer-for-user": false,
    "user-drawer-children": _ctx.userDrawerChildren,
    "user-drawer-item": _ctx.userDrawerItem,
    "warehouse-name": _ctx.warehouseName,
    onToggleCollapsed: _ctx.toggleCollapsed,
    onHeaderClick: _ctx.onHeaderClick
  }, null, 8 /* PROPS */, ["active-item-name", "drawer-topic", "help-drawer-children", "help-drawer-item", "hue-version", "img-version", "is-collapsed", "sidebar-items", "user-drawer-children", "user-drawer-item", "warehouse-name", "onToggleCollapsed", "onHeaderClick"]);
}