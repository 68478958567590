import { defineComponent } from 'vue';
import "./DropdownMenu.scss";
import DropdownPanel from "./DropdownPanel.vue";
export default defineComponent({
  name: 'DropdownMenu',
  components: {
    DropdownPanel: DropdownPanel
  },
  props: {
    text: {
      type: String,
      required: false,
      "default": ''
    },
    link: {
      type: Boolean,
      required: false,
      "default": false
    }
  }
});