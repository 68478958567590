import { render } from "./HueLink.vue?vue&type=template&id=500b7ed2"
import script from "./HueLink.vue?vue&type=script&lang=ts"
export * from "./HueLink.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "500b7ed2"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('500b7ed2', script)) {
    api.reload('500b7ed2', script)
  }
  
  module.hot.accept("./HueLink.vue?vue&type=template&id=500b7ed2", () => {
    api.rerender('500b7ed2', render)
  })

}

script.__file = "desktop/core/src/desktop/js/components/HueLink.vue"

export default script