import { render } from "./HueSidebar.vue?vue&type=template&id=7abe19c7"
import script from "./HueSidebar.vue?vue&type=script&lang=ts"
export * from "./HueSidebar.vue?vue&type=script&lang=ts"

import "./HueSidebar.vue?vue&type=style&index=0&id=7abe19c7&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "7abe19c7"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('7abe19c7', script)) {
    api.reload('7abe19c7', script)
  }
  
  module.hot.accept("./HueSidebar.vue?vue&type=template&id=7abe19c7", () => {
    api.rerender('7abe19c7', render)
  })

}

script.__file = "desktop/core/src/desktop/js/components/sidebar/HueSidebar.vue"

export default script